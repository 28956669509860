import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";

const useRemoveBootstrapBackdrops = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to remove all Bootstrap backdrops and reset scroll behavior
    const removeBootstrapBackdrops = () => {
      $(".modal-backdrop").remove(); // Removes modal backdrops
      $("body").removeClass("modal-open"); // Ensures body class is reset for modals
      $("body").css("overflow", "auto"); // Ensure scrolling is enabled on the body
    };

    // Call it whenever the route changes
    removeBootstrapBackdrops();

    // Cleanup on component unmount or route change
    return () => {
      removeBootstrapBackdrops();
    };
  }, [location]); // Trigger on route change

  useEffect(() => {
    // This will handle scroll behavior on page reload for mobile devices
    const resetBodyStyle = () => {
      $("body").css("overflow", "auto"); // Reset overflow style on body
    };

    resetBodyStyle();
  }, []); // This effect runs only once, when the component mounts

};

export default useRemoveBootstrapBackdrops;
